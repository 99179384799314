import * as Sentry from '@sentry/browser';
import { getPagePopupContainer } from '../popups/page-popup';
import { getCurrentPagePath } from '../url';

type PageView = {
    page?: string;
    contentGroup?: string;
}

export function triggerPageView( overrides: PageView = {} ): void {
    let pageView = {
        'event': 'pageview',
        'page': getCurrentPagePath(),
        'contentGroup': getContentGroup(),
    };

    if ( overrides && Object.keys( overrides ).length > 0 ) {
        pageView = {
            ...pageView,
            ...overrides,
        };
    }

    Object.keys( pageView ).forEach( ( key: string ) => {
        if ( !pageView[ key ] ) {
            Sentry.captureException( new Error( `pageview parameter ${key} with value "${pageView[ key ]}"` ) );
        }
    } );

    window.dataLayerPush( pageView );
}

function getContentGroup(): string {
    const contentGroupElement = getPagePopupContainer().querySelector( '[data-content-group]' ) as HTMLElement | null;

    if ( contentGroupElement && contentGroupElement.dataset.contentGroup ) {
        return contentGroupElement.dataset.contentGroup;
    }

    return window.contentGroup;
}
